import { Button, Row, Col } from 'react-bootstrap';
import '../styles/login.scss';
import Image1 from '../assets/home.jpg';
import Image2 from '../assets/Doc.png';

function Home() {

	return (
		<div className="home">
			<Row>
				<Col xs={{ span: 12 }} md={{ span: 8 }}>
					<div className="left">
						<img src={Image1} className="image-1"></img>
						<div className="overlay">
							<Row>
								<Col xs={{ offset: 1, span: 10 }} md={{ offset: 2, span: 3 }}>
									<a className=" btn btn-blue basic-button" href="/questionnaires/new">Comenzar</a>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				<Col xs={{ span: 12 }} md={{ span: 4 }} className="right-container">
					<div>
						<div className="d-none d-sm-none d-md-block">
							<h1>¿Necesitas ayuda?</h1>
							<Row>
								<Col xs={12} md={{ span: 8, offset: 2 }}>
									<a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}><img src={Image2} className="image-1"></img></a>
									<p className="text-justify">Te brindamos una breve <a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}><b>guía</b></a> para usar la plataforma</p>
								</Col>
							</Row>
						</div>
						<div className="d-block d-md-none d-lg-none p-2">
							<p className="text-justify"><b>¿Necesitas ayuda?</b> Consulta la <b>guía</b> de uso <a href={'/Guia_uso_cuestionarios.pdf'} target={"_blank"}>aquí</a></p>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default Home;
