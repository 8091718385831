import React, { useState } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { User, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function UserNew() {

	const [userData, setUserData] = useState<Partial<User>>({});

	const submit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!userData['name']
			|| !userData['email']) {
			Swal.fire("Usuario", "Es necesario completar todos los campos", "error");
		} else {
			Swal.fire("Usuario", "Guardando registro...");
			Swal.showLoading();
			request("/api/user", {
				method: 'POST',
				body: JSON.stringify(userData)
			})
				.then((result: ResponseResult) => {
					Swal.close();
					if (result.data) {
						window.location.href = "/users";
					} else if (result.error.status === 420) {
						Swal.fire("Usuario", "El usuario ya existe", "error");
					} else {
						console.log('El result data:', result)
						Swal.fire("Usuario", "Ocurrió un error al guardar el usuario", "error");
					}
				})
		}
	}

	const onChange = (e: any) => {
		let data = userData;
		data[e.target.name] = e.target.value;
		setUserData(data);
	}

	return (
		<div id="users-form" className="content">
			<Row>
				<Col xs={12} md={{ span: 8, offset: 2 }}>
					<h2 className="mt-5 mb-5">Nuevo usuario</h2>
					<Card className='rounded-card'>
						<Card.Body>
							<Form onSubmit={submit}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label><b>Nombre</b></Form.Label>
									<Form.Control type="text" placeholder="" name="name" onChange={onChange} className='field-styled' />
								</Form.Group>
								<Form.Group className="mb-3" controlId="email">
									<Form.Label><b>Correo electrónico</b></Form.Label>
									<Form.Control type="email" placeholder="" name="email" onChange={onChange} className='field-styled' />
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label><b>Tipo de usuario</b></Form.Label>
									<Form.Select aria-label="Tipo de pregunta" name="role" onChange={onChange} className='field-styled'>
										<option></option>
										<option value="1">Administrador</option>
										<option value="2">Profesor</option>
									</Form.Select>
								</Form.Group>
								<Row className="mt-5 justify-right">
									<Col xs={12} md={4}>
										<a className="ml-3 btn btn-danger w-100 basic-button basic-button-cancel" href="/users">
											Cancelar
										</a>
									</Col>
									<Col md={4}>
										<Button variant="primary" type="submit" className="w-100 basic-button">
											Guardar
										</Button>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default UserNew;