import { useState } from "react";
import { Accordion, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { IAnswer, IStudent, ResponseResult } from "../types";
import Swal from "sweetalert2";
import { request } from "../services/Request";
import { XCircleFill, Search } from 'react-bootstrap-icons';

function Answers() {

  const [studentCurp, setStudentCurp] = useState('');
  const [studentAnswers, setStudentAnswers] = useState<Partial<IAnswer[]>>();
  const [studentData, setStudentData] = useState<Partial<IStudent>>();
  const [showModal, setShowModal] = useState(false);
  const iconSize = 25;

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!studentCurp || !validateCurp(studentCurp)) {
      Swal.fire("Error", "La CURP ingresada es no es válida", "error");
    } else {
      Swal.fire("Buscar respuesta", "Buscando respuesta...");
      Swal.showLoading();
      request("/api/answer", {
        method: 'POST',
        body: JSON.stringify({ curp: studentCurp })
      })
        .then((result: ResponseResult) => {
          Swal.close();
          if (result.data) {            
            setStudentAnswers(result.data.answers);
            setStudentData(result.data.student);
            setShowModal(true);
          } else {
            Swal.fire("Error", "No se encontró a un alumno registrado con esa CURP", "error");
          }
        })
    }
  }

  const onChange = (e: any) => {
    let data = studentCurp;
    data = e.target.value;
    setStudentCurp(data);
  }

  const validateCurp = (text: string) => {
    const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    let flag = text.match(regex);
    return flag;
  }

  const getQuestionnaire = async (id: string) => {
    try {
      console.log('Hola')
      const questionnaire = await request("/api/questionnaire/" + id, {});
      console.log('Mi cuestionario: ', questionnaire);
      return (
        <b>{questionnaire.name}</b>
      )
    } catch (e: unknown) {
      console.log('ERROR: ', e)
    }
  }


  return (
    <div id="reports" className="content">
      <Row className='mt-5'>
        <Col xs={12} md={12} lg={12}>
          <h2>Buscar respuestas</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <p>En esta sección, podrás verificar si se registró la respuesta de un alumno por medio de su CURP, sólo tienes que ingresar los datos solicitados.</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <Card className='mt-5 rounded-card'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="cct">
                  <Form.Label><b>CURP del estudiante</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="cct" onChange={onChange} className='field-styled' />
                </Form.Group>
                <Row className="mt-5 justify-center">
                  <Col xs={12} md={3}>
                    <a className="ml-3 btn btn-danger w-100 basic-button basic-button-cancel" href="/students/list" title='Cancelar'>
                      Cancelar <XCircleFill size={iconSize} />
                    </a>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button variant="primary" type="submit" className="w-100 basic-button basic-button">
                      Buscar <Search size={iconSize} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => { setShowModal(false) }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>
              Resultados de la búsqueda
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            studentData && (
              <>
                <div className="mb-2">
                  <b>Información del alumno: </b>
                </div>
                <Table striped bordered hover responsive className='table-styled'>
                  <thead>
                    <tr>
                      <th>CURP</th>
                      <th>Nombre</th>
                      <th>Turno</th>
                      <th>CCT</th>
                      <th>Subsistema</th>
                      <th>Plantel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <tr>
                        <td>
                          {studentData.curp}
                        </td>
                        <td>
                          {studentData.name}
                        </td>
                        <td>
                          {studentData.schedule}
                        </td>
                        <td>
                          {studentData.cct}
                        </td>
                        <td>
                          {studentData.subsystem}
                        </td>
                        <td>
                          {studentData.school}
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </>
            )
          }
          {
            studentAnswers && studentAnswers.length > 0 ? (
              <>
                <p>
                  {studentAnswers.length === 1 ? 'Se encontró 1 respuesta' : `Se encontraron ${studentAnswers.length} respuestas`}
                </p>
                {
                  studentAnswers.map((answer: any, index: number) => (
                    <Accordion defaultActiveKey="1" key={'answer' + index + 1}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Respuesta #{index + 1}</Accordion.Header>
                        <Accordion.Body>
                          <Table striped bordered hover responsive className='table-styled'>
                            <thead>
                              <tr>
                                <th>{answer.questionnaire.name}</th>                                
                              </tr>
                            </thead>
                            <tbody>
                              {
                                <>
                                  {JSON.stringify(answer.fields, null, 1)}
                                </>
                              }
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))
                }
              </>
            ) : (
              <p>
                No se encontró ninguna respuesta registrada con la CURP de este alumno.
              </p>
            )
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Answers; 