import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import { Input, Select } from '../../components/FormComponents';
import '../../styles/form.scss';
import Swal from 'sweetalert2';
import { Student, School } from '../../types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import headerJalisco from '../../assets/headerJalisco.png';
import * as Sentry from "@sentry/react";

function StudentQuestionnaireSignNoID() {

	const [userData, setUserData]: any = useState<Partial<Student>>({});
	const [schools, setSchools] = useState<School[]>([]);
	const [ subsystems, setSubsystems ] = useState([]);
	const [ selectedSub, setSelectedSub ] = useState("");

	useEffect(() => {
		getSubsystem();
	}, []);

	const getSubsystem = async () => {
		try {
			Swal.fire("Diagnóstico", "Cargando subsistemas...");
			Swal.showLoading();
			const result = await request('/api/school/subsystems', {});
			Swal.close();
			if (result.data) {
				setSubsystems(result.data);
			} else {
				Sentry.captureException(result);
				Swal.fire("Diagnóstico", "Ocurrió un error al cargar los subsistemas. Error 2.", "error");
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al cargar las subsistemas. Error 1.", "error")
		}
	}

	const getSchools = async (sub: string) => {
		try {
			Swal.fire("Diagnóstico", "Cargando escuelas...");
			Swal.showLoading();
			const result = await request('/api/school/subsystem', {
				method: 'POST',
				body: JSON.stringify({ sub })
			});
			Swal.close();
			if (result.data) {
				setSchools(result.data);
			} else {
				Sentry.captureException(result);
				Swal.fire("Diagnóstico", "Ocurrió un error al cargar las escuelas. Error 2.", "error");
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al cargar las escuelas. Error 1.", "error")
		}
	}

	const createStudent = async (flag: boolean) => {
		try {
			if (!userData.name ||
				!userData.school ||
				(!flag && !userData.curp) ||
				!userData.year ||
				!userData.schedule) {
				Swal.fire("Diagnóstico", "Hay que completar todos los campos", "error");
			} else if (!flag && !validateCurp(userData.curp)) {
				Swal.fire("Diagnóstico", "La CURP ingresada es inválida", "error");
			} else {
				const result = await request("/api/student", {
					method: 'POST',
					body: JSON.stringify(userData)
				});
				if (result.data) {
					Swal.fire("Diagnóstico", "Registro completado. Al cerrar esta ventana serás redirigido a la prueba.", "success")
						.then(() => {
							window.location.href = "/responder/Evaluacion-de-aprendizajes-anio-" + userData.year + "?token=" + result.data.token;
						})
				} else {
					Sentry.captureException(result);
					Swal.fire("Diagnóstico", "Ocurrió un error al registrarte. Error 2.", "error");
				}
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al registrarte. Error 1.", "error")
		}
	}

	const onChange = (e: any) => {
		let data = { ...userData };
		if (e.target.name === 'curp') {
			data[e.target.name] = e.target.value.toUpperCase().trim();
		} else {
			data[e.target.name] = e.target.value.trim();
		}
		setUserData(data);
	}

	const onChangeSchool = (e: any) => {
		let data = { ...userData };
		data['cct'] = e[0] ? e[0].cct : undefined;
		data['school'] = e[0] ? e[0].school : undefined;
		data['subsystem'] = e[0] ? e[0].subsystem : undefined;
		setUserData(data);
	}

	const onChangeSubsystem = (e:any) => {
		const sub = e.target.value;
		setSelectedSub(sub);
		getSchools(sub);
	}

	const validateCurp = (text: string) => {
		const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
		let flag = text.match(regex);
		return flag;
	}

	return (
		<div className='answer-form content preview'>
			<div className="logos">
				<Row>
					<Col xs={5} style={{ backgroundColor: 'white', height: '90px', borderTopRightRadius: '60px', borderBottomRightRadius: '60px', justifyContent: 'center', alignContent: 'center', marginTop: '40px', marginBottom: '50px' }}>
						<Row>
							<Col>
								<img alt='Jalisto' src={headerJalisco}></img>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<Row>
				<Col xs={12} md={{ offset: 2, span: 8 }}>
					<h2 className="mt-5 mb-5">Recrea Avanza 2023</h2>
					<Card>
						<Card.Body>
							<div className="mt-3">
								<Input
									onChange={onChange}
									item={{
										title: "Ingresa tu nombre completo:",
										type: 'text',
										name: 'name',
									}}></Input>
							</div>
							<div className="mt-3">
								<Input
									onChange={onChange}
									item={{
										title: "Ingresa tu CURP:",
										type: 'text',
										name: 'curp',
									}}></Input>
							</div>
							<div className="mt-3">
								<Select
									onChange={onChange}
									item={{
										title: "Ingresa tu año escolar:",
										type: 'text',
										name: 'year',
										options: [
											{
												label: '1',
												value: '1'
											},
											{
												label: '2',
												value: '2'
											},
											{
												label: '3',
												value: '3'
											}
										]
									}}></Select>
							</div>
							<div className="mt-3">
								<Select
									onChange={onChange}
									item={{
										title: "Ingresa tu turno",
										type: 'text',
										name: 'schedule',
										options: [
											{
												label: 'Matutino',
												value: 'Matutino'
											},
											{
												label: 'Vespertino',
												value: 'Vespertino'
											},
											{
												label: 'Otro',
												value: 'Otro'
											}
										]
									}}></Select>
							</div>
							{ subsystems.length > 0 && (
								<div className="mt-3">
									<Form.Group>
										<Form.Label>Selecciona tu subsistema:</Form.Label>
										<Typeahead
											id="subsystem"
											labelKey={(option: any) => `${option}`}
											onChange={onChangeSubsystem}
											options={subsystems}
											placeholder="Escribe el nombre..."
										/>
									</Form.Group>
								</div>
							) }
							{ selectedSub !== "" && (
								<div className="mt-3">
									<Form.Group>
										<Form.Label>Selecciona tu escuela:</Form.Label>
										<Typeahead
											id="schools"
											//labelKey={(option: any) => `${option.school} - ${option.cct}`}
											labelKey={(option: any) => `${option.name} - ${option.cct}`}
											onChange={onChangeSchool}
											options={schools}
											placeholder="Escribe el nombre..."
										/>
									</Form.Group>
								</div>
							) }
							<Row className="mt-3 justify-content-center">
								<Col xs={12} md={4}>
									<Button className="w-100" onClick={() => createStudent(false)}>Registrarse</Button>
								</Col>
							</Row>

							<button className='no-curp-2 btn btn-link' onClick={() => createStudent(true)}>Soy extranjero, no tengo CURP</button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default StudentQuestionnaireSignNoID;