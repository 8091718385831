import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { request } from '../../services/Request';
import { ISchool } from '../../types';
import { CloudUploadFill, PlusCircleFill, PencilSquare, XSquareFill } from 'react-bootstrap-icons';

function SchoolList() {

  const [schools, setSchools] = useState<ISchool[]>([]);
  const iconSize = 25;

  useEffect(() => {
    getSchools();
  }, []);

  const getSchools = async (text?: string) => {
    try {
      if (!text) {
        Swal.fire("Planteles", "Cargando...");
        Swal.showLoading();
      }
      const result = await request(`/api/school${text ? '?text=' + text : ''}`, {});
      Swal.close();
      if (result.data) {
        setSchools(result.data);
      }
    } catch (e: unknown) {
      Swal.fire("Planteles", "Ocurrió un error al cargar los datos", "error");
    }
  }

  const remove = (item: ISchool) => {
    Swal.fire({
      title: 'Eliminar plantel',
      html: `¿Confirmas que deseas eliminar el plantel <b>${item.name}</b>?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    })
      .then(async (result: any) => {
        if (result.isConfirmed) {
          Swal.fire("Eliminar plantel", "Realizando petición..");
          Swal.showLoading()
          const result = await request('/api/school/' + item._id, {
            method: 'DELETE'
          });
          if (result.data) {
            Swal.fire("Eliminar plantel", "El plantel se ha eliminado con exito", "success");
            getSchools();
          } else {
            Swal.fire("Eliminar plantel", "Ocurrió un error al realizar el proceso", "error");
          }
        }
      })
  }

  const search = (e: any) => {
    const value = e.target.value;
    if (value != '') {
      getSchools(value);
    } else {
      getSchools();
    }
  }


  return (
    <div className="content list">
      <Row>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <h2>Planteles educativos</h2>
          <Card className='rounded-card'>
            <Card.Body>
              <Row style={{ justifyContent: 'right' }}>
                <Col xs={12} md={3}>
                  <Link to="/schools/upload">
                    <Button variant="primary" className="w-100 mb-3 basic-button">Subir <CloudUploadFill size={20} /></Button>
                  </Link>
                </Col>
                <Col xs={12} md={3}>
                  <Link to="/schools/new">
                    <Button variant="primary" className="w-100 mb-3 basic-button">Crear nuevo <PlusCircleFill size={20} /></Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="searchForm" className='mb-3'>
                    <Form.Control
                      type="text"
                      placeholder="Buscar por Entidad, Municipio, CCT, Nombre o Subsistema"
                      onChange={search}
                      className='field-styled'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Table striped bordered hover responsive className='table-styled'>
                <thead>
                  <tr>
                    <th>Entidad Administrativa</th>
                    <th>Municipio</th>
                    <th>CCT</th>
                    <th>Nombre</th>
                    <th>Subsistema</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    schools.map((item: ISchool, key: number) => (
                      <tr key={key}>
                        <td>{item.administrative_entity}</td>
                        <td>{item.municipality}</td>
                        <td>{item.cct}</td>
                        <td>{item.name}</td>
                        <td>{item.subsystem}</td>
                        <td style={{textAlign: 'center'}}>
                          <Link to={"/schools/edit/" + item._id}>
                            <Button variant="primary" className='edit-button'><PencilSquare size={iconSize} /></Button>
                          </Link>
                          <Button variant="danger" className="delete-button" onClick={() => { remove(item) }}><XSquareFill size={iconSize} /></Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SchoolList;
