import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Table } from 'react-bootstrap';
import { ResponseResult, Student } from '../../types';
import { request } from '../../services/Request';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

function StudentsList() {

	const [selectedFiles, setFiles]: any = useState([]);
	const [students, setStudents] = useState<Student[]>([]);
	const [teacher, setTeacher] = useState({
		code_id: 0
	})

	useEffect(() => {
		getStudents();
		me();
	}, [])

	const getStudents = async () => {
		try {
			Swal.fire("Alumnos", "Cargando...");
			Swal.showLoading();
			const result = await request("/api/student", {});
			Swal.close();
			if (result.data) {
				setStudents(result.data);
			}
		} catch (e: unknown) {
			Swal.fire("Alumnos", "Ocurrió un error al recuperar los alumnos", "error");
		}
	}

	const me = async () => {
		try {
			Swal.fire("Alumnos", "Cargando...");
			Swal.showLoading();
			const result = await request("/api/auth/me", {});
			Swal.close();
			if (result.data) {
				setTeacher(result.data);
			}
		} catch (e: unknown) {
			Swal.fire("Alumnos", "Ocurrió un error al recuperar los alumnos", "error");
		}
	}

	const onDrop = (acceptedFiles: any) => {
		let files = selectedFiles;
		files = acceptedFiles;
		setFiles(files);
	};

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop: onDrop,
		accept: {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
			'application/vnd.ms-excel': ['.xlsx']
		}
	});

	const upload = () => {
		if (selectedFiles.length > 0) {
			Swal.fire('Subir alumnos', 'Cargando...');
			Swal.showLoading();
			const data = new FormData();
			for (let file of selectedFiles) {
				data.append('files', file);
			}
			request('/api/student/upload', {
				method: 'POST',
				file: true,
				body: data
			})
				.then((result: ResponseResult) => {
					Swal.close();
					if (result.data) {
						Swal.fire('Subir alumnos', 'Los alumnos se subieron correctamente', 'success');
						getStudents();
					} else {
						Swal.fire('Subir alumnos', 'Ocurrió un error', 'error');
					}
				})
		}
	}

	const remove = async (student: Student) => {
		Swal.fire({
			title: 'Eliminar alumno',
			html: `¿Confirmas que deseas eliminar a <b>${student.name}</b>?`,
			confirmButtonText: 'Sí',
			cancelButtonText: 'Sí',
			showCancelButton: true,
			showConfirmButton: true
		}).then(async (result) => {
			if (result.isConfirmed) {
				Swal.fire("Eliminar alumno", "Cargando...");
				Swal.showLoading();
				const response = await request('/api/student/' + student._id, {
					method: 'DELETE'
				});
				if (response.data) {
					getStudents();
				} else {
					Swal.fire("Eliminar alumno", "Ocurrió un error al eliminar el alumno", "error");
				}
			}
		})
	}

	return (
		<div id="upload" className="content">
			<Row>
				<Col xs={24} md={{ span: 8, offset: 2 }}>
					<Card className="mt-5">
						<Card.Body>
							<h3>Alumnos</h3>
							<a href="/plantilla_alumnos.xlsx" className="mb-3">Descargar plantilla</a>
							<div className='file-drop justify-center mt-5' {...getRootProps()}>
								<input {...getInputProps({ multiple: false })} />
								<p className="text-center mb-4">Arrastra y suelta tus archivo o presiona aquí para elegirlo.</p>
							</div>
							<p className='mt-3'><b>Archivo seleccionado:</b></p>
							<ul className="mb-5">
								{
									selectedFiles.map((file: any, key: number) => (
										<li key={key}>
											{file.name}
										</li>
									))
								}
							</ul>
							<Button className="btn btn-primary mb-5 basic-button" onClick={upload}>Subir archivo</Button>
							<p><b>Maestro ID:</b> {teacher.code_id}</p>
							<Table className='mt-2'>
								<thead>
									<tr>
										<th>ID</th>
										<th>Nombre</th>
										<th>Opciones</th>
									</tr>
								</thead>
								<tbody>
									{
										students.map((student: Student, key: number) => (
											<tr key={key}>
												<td>{student.number_id}</td>
												<td>{student.name}</td>
												<td>
													<Button className='btn btn-danger' onClick={() => remove(student)}>Eliminar</Button>
												</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default StudentsList;