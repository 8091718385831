import React, { useRef } from 'react';
import { Row, Col, Card, Form, Button, Modal, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import { Input, Select } from '../../components/FormComponents';
import '../../styles/form.scss';
import Swal from 'sweetalert2';
import { Student, School } from '../../types';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import TypeaheadRef from 'react-bootstrap-typeahead/types/core/Typeahead';
import headerJalisco from '../../assets/headerJalisco.png';
import * as Sentry from "@sentry/react";

function StudentQuestionnaireSign() {

	const [userData, setUserData]: any = useState<Partial<Student>>({});
	const [student, setStudent] = useState<Partial<Student>>({});
	const [studentsDuplicated, setStudentsDuplicated] = useState<Partial<Student[]>>();
	const [showStudent, setShowStudent] = useState(false);
	const [showStudents, setShowStudents] = useState(false);
	const [rejected, setRejected] = useState(0);
	const [showNew, setShowNew] = useState(false);
	const [schools, setSchools] = useState<School[]>([]);
	const [isForeign, setForeign] = useState(false);
	const [subsystems, setSubsystems] = useState([]);
	const [selectedSub, setSelectedSub] = useState("");
	const thref = React.createRef<TypeaheadRef>();
	const [diagnosticEnabled, setDiagnosticEnabled] = useState(false);

	useEffect(() => {
		getSubsystem();
		getDiagnosticStatus();
	}, []);

	const getSubsystem = async () => {
		try {
			Swal.fire("Diagnóstico", "Cargando subsistemas...");
			Swal.showLoading();
			const result = await request('/api/school/subsystems', {});
			Swal.close();
			if (result.data) {
				setSubsystems(result.data);
			} else {
				Swal.fire("Diagnóstico", "Se produjo un error al cargar los subsistemas. Por favor, verifica tu conexión a Internet y vuelve a intentarlo.", "error");
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al cargar las subsistemas. Error 1.", "error")
		}
	}

	const getSchools = async (sub: string) => {
		try {
			Swal.fire("Diagnóstico", "Cargando escuelas...");
			Swal.showLoading();
			const result = await request('/api/school/subsystem', {
				method: 'POST',
				body: JSON.stringify({ sub })
			});
			Swal.close();
			if (result.data) {
				setSchools(result.data);
			} else {
				Sentry.captureException(result);
				Swal.fire("Diagnóstico", "Ocurrió un error al cargar las escuelas. Error 2.", "error");
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al cargar las escuelas. Error 1.", "error")
		}
	}

	const searchStudent = async () => {
		try {
			if (!userData.curp || !validateCurp(userData.curp)) {
				Swal.fire("CURP NO VÁLIDA", "La CURP que has ingresado no es válida. Por favor, verifica que esté escrita correctamente y que no contenga espacios en blanco.", "error");
			} else {
				const result = await request("/api/student/search", {
					method: 'POST',
					body: JSON.stringify({
						field: 'curp',
						value: userData.curp
					})
				});
				if (result.data) {
					if (result.data.length > 1) {
						setStudentsDuplicated(result.data);
						setShowStudents(true);
					} else {
						setStudent(result.data);
						setShowStudent(true);
					}
				} else if (result.error.status === 420) {
					Swal.fire("Diagnóstico", "No hay un estudiante registrado con esta CURP", "error");
					setRejected(rejected + 1);
				} else {
					Swal.fire("Diagnóstico", "Se produjo un error al buscar el estudiante. Por favor, verifica tu conexión a Internet y vuelve a intentarlo.", "error");
				}
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Se produjo un error al buscar el estudiante.", "error")
		}
	}

	const createStudent = async () => {
		try {
			if (!userData.name ||
				!userData.school ||
				(!isForeign && !userData.curp) ||
				!userData.year ||
				!userData.schedule) {
				Swal.fire("Diagnóstico", "Hay que completar todos los campos", "error");
			} else if (!isForeign && !validateCurp(userData.curp)) {
				Swal.fire("Diagnóstico", "La CURP ingresada es inválida", "error");
			} else {
				const result = await request("/api/student", {
					method: 'POST',
					body: JSON.stringify(userData)
				});
				if (result.data) {
					Swal.fire("Diagnóstico", "Registro completado. Al cerrar esta ventana serás redirigido a la prueba.", "success")
						.then(() => {
							window.location.href = "/responder/Evaluacion-de-aprendizajes-anio-" + userData.year + "?token=" + result.data.token;
						})
				} else {
					Sentry.captureException(result);
					Swal.fire("Diagnóstico", "Ocurrió un error al registrarte. Error 2.", "error");
				}
			}
		} catch (e: unknown) {
			Sentry.captureException(e);
			Swal.fire("Diagnóstico", "Ocurrió un error al registrarte. Error 1.", "error")
		}
	}

	const getDiagnosticStatus = async () => {
		try {
			const result = await request("/api/config/getDiagnosticStatus", {});
			if (result) {
				console.log('Este es mi result', result.data.diagnostic_status);
				setDiagnosticEnabled(result.data.diagnostic_status);
			}
		} catch (e) {
			console.log('Error', e);
		}
	}

	const onChange = (e: any) => {
		let data = { ...userData };
		if (e.target.name === 'curp') {
			data[e.target.name] = e.target.value.toUpperCase().trim();
		} else {
			data[e.target.name] = e.target.value.trim();
		}
		setUserData(data);
	}

	const onChangeSchool = (e: any) => {
		let data = { ...userData };
		data['cct'] = e[0] ? e[0].cct : undefined;
		data['school'] = e[0] ? e[0].name : undefined;
		data['subsystem'] = e[0] ? e[0].subsystem : undefined;
		setUserData(data);
	}

	const onChangeSubsystem = (e: any) => {
		thref.current?.clear()
		const sub = e;
		setSelectedSub(sub);
		getSchools(sub);
	}

	const validateCurp = (text: string) => {
		const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
		let flag = text.match(regex);
		return flag;
	}

	const rejectStudent = () => {
		setRejected(rejected + 1);
		setShowStudent(false);
		setShowStudents(false);
	}

	const redirectForm = async (studentDuplicated?: Student) => {
		if (studentDuplicated) {
			window.location.href = "/responder/Evaluacion-de-aprendizajes-anio-" + studentDuplicated.year + "?token=" + studentDuplicated.token;
		} else {
			window.location.href = "/responder/Evaluacion-de-aprendizajes-anio-" + student.year + "?token=" + student.token;
		}
	}

	const noCurp = () => {
		setUserData({});
		setForeign(true);
		setShowNew(true);
	}

	return (
		<div className='answer-form content preview'>
			<div className="logos">
				<Row>
					<Col xs={5} style={{ backgroundColor: 'white', height: '90px', borderTopRightRadius: '60px', borderBottomRightRadius: '60px', justifyContent: 'center', alignContent: 'center', marginTop: '40px', marginBottom: '50px' }}>
						<Row>
							<Col>
								<img alt='Jalisco' src={headerJalisco}></img>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			{
				diagnosticEnabled && diagnosticEnabled === true ? (
					<Row>
						<Col xs={12} md={{ offset: 2, span: 8 }}>
							<h2 className="mt-5 mb-5">Recrea Avanza 2023</h2>
							{
								!showNew && (
									<div>
										<Card className='rounded-card'>
											<Card.Body>
												<Form>
													<Form.Group>
														<Input
															onChange={onChange}
															item={{
																title: "<b>Ingresa tu CURP para identificarte:<br/><label>Si no sabes tu CURP, puedes consultarlo <a target='_blank' href='https://www.gob.mx/curp/'>aquí</a></label></b>",
																type: 'text',
																name: 'curp',
															}}></Input>
													</Form.Group>
												</Form>
												<Row className="mt-3 justify-content-center">
													<Col xs={12} md={4}>
														<Button className="w-100 basic-button" onClick={searchStudent}>Buscar</Button>
													</Col>
													{
														rejected > 1 && (
															<Col xs={12} md={4}>
																<Button className=" btn-secondary w-100" onClick={() => { setShowNew(true) }}>Registrarse como nuevo</Button>
															</Col>
														)
													}
												</Row>
											</Card.Body>
										</Card>
										<Button className='no-curp btn btn-link basic-button' onClick={noCurp} style={{ color: 'white' }}>Soy extranjero, no tengo CURP</Button>
									</div>
								)
							}
							{
								showNew && (
									<Card>
										<Card.Body>
											<div className="mt-3">
												<Input
													onChange={onChange}
													item={{
														title: "Ingresa tu nombre completo:",
														type: 'text',
														name: 'name',
													}}></Input>
											</div>
											{
												!isForeign && (
													<div className="mt-3">
														<Input
															onChange={onChange}
															item={{
																title: "Ingresa tu CURP:",
																type: 'text',
																name: 'curp',
															}}></Input>
													</div>
												)
											}
											<div className="mt-3">
												<Select
													onChange={onChange}
													item={{
														title: "Ingresa tu año escolar:",
														type: 'text',
														name: 'year',
														options: [
															{
																label: '1',
																value: '1'
															},
															{
																label: '2',
																value: '2'
															},
															{
																label: '3',
																value: '3'
															}
														]
													}}></Select>
											</div>
											<div className="mt-3">
												<Select
													onChange={onChange}
													item={{
														title: "Ingresa tu turno",
														type: 'text',
														name: 'schedule',
														options: [
															{
																label: 'Matutino',
																value: 'Matutino'
															},
															{
																label: 'Vespertino',
																value: 'Vespertino'
															},
															{
																label: 'Otro',
																value: 'Otro'
															}
														]
													}}></Select>
											</div>
											{subsystems.length > 0 && (
												<div className="mt-3">
													<Form.Group>
														<Form.Label>Selecciona tu subsistema:</Form.Label>
														<Typeahead
															id="subsystem"
															labelKey={(option: any) => `${option}`}
															onChange={onChangeSubsystem}
															options={subsystems}
															placeholder="Escribe el nombre..."
														/>
													</Form.Group>
												</div>
											)}
											{selectedSub !== "" && (
												<div className="mt-3">
													<Form.Group>
														<Form.Label>Selecciona tu escuela:</Form.Label>
														<Typeahead
															id="schools"
															labelKey={(option: any) => `${option.name} - ${option.cct}`}
															onChange={onChangeSchool}
															options={schools}
															ref={thref}
															placeholder="Escribe el nombre..."
														/>
													</Form.Group>
												</div>
											)}
											<Row className="mt-3 justify-content-center">
												<Col xs={12} md={4}>
													<Button className="w-100" onClick={createStudent}>Registrarse</Button>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								)
							}
						</Col>
					</Row>
				) : (
					<Row className='justify-center'>
						<Col xs={10}>
							<Card className='rounded-card'>
								<Card.Body>
									<h3 className='text-center'>Recrea Avanza 2023 agradece tu participación, la convocatoria de evaluación ya fue cerrada.</h3>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				)
			}
			<Modal show={showStudent} onHide={() => { setShowStudent(false) }} size={'lg'}>
				<Modal.Header closeButton>
					<Modal.Title><h3>¿Eres este estudiante?</h3></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table>
						<tbody>
							<tr>
								<td>
									<p><b>Nombre:</b></p>
								</td>
								<td> {student.name} </td>
							</tr>
							<tr>
								<td>
									<p><b>Escuela:</b></p>
								</td>
								<td> {student.school} </td>
							</tr>
							<tr>
								<td>
									<p><b>Subsistema:</b></p>
								</td>
								<td> {student.subsystem} </td>
							</tr>
							<tr>
								<td>
									<p><b>Turno:</b></p>
								</td>
								<td> {student.schedule} </td>
							</tr>
							<tr>
								<td>
									<p><b>Año:</b></p>
								</td>
								<td> {student.year} </td>
							</tr>
							<tr>
								<td>
									<p><b>CURP:</b></p>
								</td>
								<td> {student.curp} </td>
							</tr>
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className='basic-button basic-button-cancel' onClick={rejectStudent}>
						No
					</Button>
					<Button variant="primary" className='basic-button' onClick={() => { redirectForm() }}>
						Sí
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showStudents} onHide={() => { setShowStudents(false) }} size={'lg'}>
				<Modal.Header closeButton>
					<Modal.Title>¿Eres alguno de estos estudiantes?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						studentsDuplicated?.map((student: any, index: number) => (
							<>
								<Table>
									<tbody key={'table-' + index}>
										<tr>
											<td>
												<p><b>Nombre:</b></p>
											</td>
											<td> {student.name} </td>
										</tr>
										<tr>
											<td>
												<p><b>Escuela:</b></p>
											</td>
											<td> {student.school} </td>
										</tr>
										<tr>
											<td>
												<p><b>Subsistema:</b></p>
											</td>
											<td> {student.subsystem} </td>
										</tr>
										<tr>
											<td>
												<p><b>Turno:</b></p>
											</td>
											<td> {student.schedule} </td>
										</tr>
										<tr>
											<td>
												<p><b>Año:</b></p>
											</td>
											<td> {student.year} </td>
										</tr>
										<tr>
											<td>
												<p><b>CURP:</b></p>
											</td>
											<td> {student.curp} </td>
										</tr>
									</tbody>
								</Table>
								<Row className='mt-2' style={{ textAlign: 'right' }}>
									<Col xs={12}>
										<Button variant="primary" className='basic-button' onClick={() => { redirectForm(student) }}>
											Soy este estudiante
										</Button>
									</Col>
								</Row>
								<div style={{ width: '100%', height: '2px', backgroundColor: '#eb5d80', marginTop: '50px', marginBottom: '50px' }}></div>
							</>
						))
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className='basic-button basic-button-cancel' onClick={rejectStudent}>
						No soy ninguno de estos estudiantes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default StudentQuestionnaireSign;