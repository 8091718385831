import React, { useState, useEffect, useContext } from 'react';
import { request } from '../services/Request';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { ResponseResult } from '../types';
import '../styles/header.scss';
import Session from '../context/Session';
import { HouseFill, 
				FileEarmarkFill, 
				PersonLinesFill, 
				BuildingFill, 
				PersonRolodex, 
				FileEarmarkBarGraphFill, 
				BoxArrowRight,
				BoxArrowLeft,
				ClipboardCheckFill,
				GraphUpArrow } from 'react-bootstrap-icons'

function Header() {

	const [isAuth, setAuth] = useState(false);
	const [role, setRole] = useState(0);
	const [isLogged, setLogged] = useState(false);
	const session = useContext(Session);
	const iconSize = 25;

	useEffect(() => {
		if (session._id != '' || session._id != undefined || session._id != null) {
			setLogged(true);
		}
	}, [session._id]);

	useEffect(() => {
		if (isLogged) {
			request('/api/auth/me', {
				noRedirect: true
			}).then((result: ResponseResult) => {
				if (result.data) {
					setAuth(true);
					setRole(result.data.role);
				}
			});
		}
	}, [session._id]);

	const close = () => {
		if (localStorage.token) {
			delete localStorage.token;
		}
		window.location.href = "/";
	}

	return (
		<React.Fragment>
			<Navbar expand="lg" className="header" fixed={"top"}>
				<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link href="/"><HouseFill size={iconSize}/><br></br> Inicio</Nav.Link>
							{
								isAuth ? (
									<>
										<Nav.Link href="/questionnaires"><FileEarmarkFill size={iconSize} /><br></br> Cuestionarios</Nav.Link>
										{
											role == 1 && (
												<>
													<Nav.Link href="/users"><PersonLinesFill size={iconSize} /><br></br>Usuarios</Nav.Link>
													<Nav.Link href="/schools"><BuildingFill size={iconSize} /><br></br>Planteles</Nav.Link>
													<Nav.Link href="/students/list"><PersonRolodex size={iconSize} /><br></br>Alumnos</Nav.Link>
													<Nav.Link href="/reports"><FileEarmarkBarGraphFill size={iconSize} /><br></br>Reportes</Nav.Link>
													<Nav.Link href="/numerals"><GraphUpArrow size={iconSize} /><br></br>Numeralía</Nav.Link>
													<Nav.Link href="/answers"><ClipboardCheckFill size={iconSize} /><br></br>Respuestas</Nav.Link>
												</>

											)
										}
										{/* {
											role == 2 && (
												<Nav.Link href="/students">Alumnos</Nav.Link>
											)
										} */}
										<Nav.Link href="#" onClick={close}><BoxArrowRight size={iconSize} /><br></br>Salir</Nav.Link>
									</>
								) : (
									<Nav.Link href="/login"><BoxArrowLeft size={iconSize} /><br></br>Iniciar Sesión</Nav.Link>
								)
							}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</React.Fragment>
	)
}

export default Header;