import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { ResponseResult, IStudent, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { XCircleFill, CheckCircleFill } from 'react-bootstrap-icons';

function StudentEdit() {

  const [studentData, setStudentData] = useState<Partial<IStudent>>({});
  const { id } = useParams<RouteParam>();
  const iconSize = 25;

  useEffect(() => {
    getStudent();
  }, []);

  const getStudent = () => {
    request('/api/student/' + id, {})
      .then((result: ResponseResult) => {
        if (result.data) {
          setStudentData(result.data);
        }
      })
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!studentData['curp'] || !validateCurp(studentData.curp)) {
      Swal.fire("Alumno", "La CURP ingresada es inválida", "error");
    } else {
      if (!studentData['name']
        || !studentData['year']
        || !studentData['schedule']
        || !studentData['cct']
        || !studentData['school']
        || !studentData['subsystem']) {
        Swal.fire("Editar alumno", "Es necesario completar todos los campos", "error");
      } else {
        Swal.fire("Editar alumno", "Guardando registro...");
        Swal.showLoading();
        request("/api/student/" + id, {
          method: 'PUT',
          body: JSON.stringify(studentData)
        })
          .then((result: ResponseResult) => {
            Swal.close();
            if (result.data) {
              Swal.fire("Editar alumno", "Datos del alumno actualizados con éxito", "success")
                .then(() => {
                  window.location.href = "/students/list";
                })
            } else {
              Swal.fire("Alumno", "Ocurrió un error al guardar el alumno", "error");
            }
          })
      }
    }
  }

  const onChange = (e: any) => {
    let data = studentData;
    data[e.target.name] = e.target.value;
    setStudentData(data);
  }

  const validateCurp = (text: string) => {
    const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    let flag = text.match(regex);
    return flag;
  }

  return (
    <div id="users-form" className="content">
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} className='mt-3'>
          <h2 className="mt-5 mb-5">Editar alumno</h2>
          <Card className='rounded-card'>
            <Card.Body>
              <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="curp">
                  <Form.Label><b>CURP</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="curp" onChange={onChange} defaultValue={studentData.curp} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label><b>Nombre completo</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="name" onChange={onChange} defaultValue={studentData.name} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="year">
                  <Form.Label><b>Grado</b></Form.Label>                 
                  <Form.Select name="year" onChange={onChange} className='field-styled'>
                    <option></option>
                    <option value="1" selected={studentData.year === '1'}>1°</option>
                    <option value="2" selected={studentData.year === '2'}>2°</option>
                    <option value="3" selected={studentData.year === '3'}>3°</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="schedule">
                  <Form.Label><b>Turno</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="schedule" onChange={onChange} defaultValue={studentData.schedule} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cct">
                  <Form.Label><b>CCT del plantel educativo</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="cct" onChange={onChange} defaultValue={studentData.cct} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="school">
                  <Form.Label><b>Nombre del plantel educativo</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="school" onChange={onChange} defaultValue={studentData.name} className='field-styled' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="subsystem">
                  <Form.Label><b>Subsistema</b></Form.Label>
                  <Form.Control type="text" placeholder="" name="subsystem" onChange={onChange} defaultValue={studentData.subsystem} className='field-styled' />
                </Form.Group>
                <Row className="mt-5 justify-right">
                  <Col xs={12} md={3}>
                    <a className="ml-3 btn btn-danger w-100 basic-button basic-button-cancel" href="/students/list" title='Cancelar'>
                      Cancelar <XCircleFill size={iconSize} />
                    </a>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button variant="primary" type="submit" className="w-100 basic-button basic-button">
                      Guardar <CheckCircleFill size={iconSize} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StudentEdit;