import React from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';

export type Props = {
    item: any;
    value?: string | string[];
    onChange?: any;
    disabled?: boolean;
    min?: number;
    defaultValue?:string;
}

export function Input({item, value, onChange, disabled}:Props){
    return (
        <Form.Group controlId={item.name}>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div></Form.Label>
            {
                item.type == 'phone' ? (
                    <InputGroup>
                        <InputGroup.Text>+52</InputGroup.Text>
                        <Form.Control min={ item.min ? item.min : 0} defaultValue={value} required={!item.optional} type="text" name={item.name} onChange={onChange} disabled={disabled} data-validate={ item.type } className='field-styled'/>
                    </InputGroup>
                ) : (
                    <Form.Control min={ item.min ? item.min : 0} defaultValue={value} required={!item.optional} type="text" name={item.name} onChange={onChange} disabled={disabled} data-validate={ item.type } className='field-styled'/>
                )
            }
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}

export function InputNumber({item, value, onChange, disabled, min}:Props){
    return (
        <Form.Group controlId={item.name}>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div></Form.Label>
            <Form.Control defaultValue={value} data-min={min} required={!item.optional} type="number" name={item.name} onChange={onChange} disabled={disabled}/>
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}

export function InputDate({item, value, onChange, disabled}:Props){
    return (
        <Form.Group controlId={item.name}>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div></Form.Label>
            <Form.Control defaultValue={value} required={!item.optional} type="date" name={item.name} onChange={onChange} disabled={disabled}/>
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}

export function Select({item, value, onChange, disabled, defaultValue}:Props){
    return (
        <Form.Group controlId={item.name}>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div></Form.Label>
            <Form.Control as="select" name={item.name} onChange={onChange} required={!item.optional} className="w-100" disabled={disabled} value={defaultValue}>
                <option></option>
                {
                    item.options.map((option:any,indexOption:number)=>(
                        <>
                            {
                                option.label ? (
                                    <option value={option.value} key={indexOption} selected={value == option.value}>{option.label}</option>
                                ) : (
                                    <option value={option} key={indexOption} selected={value == option}>{option}</option>
                                )
                            }
                        </>
                    ))
                }
            </Form.Control>
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}

export function Radio({item, value, onChange, disabled}:Props){

    return (
        <Form.Group controlId={item.name}>
            <Form.Label>
                <div dangerouslySetInnerHTML={{__html: item.title}}></div> 
            </Form.Label>
            <div>
                {
                    item.options.map((option:any,indexOption:number)=>(
                        <>
                            {
                                option.label ? (
                                    <Form.Check type={'radio'} id={item.name+`-${indexOption}`} className="radio-aligned row align-items-center">
                                        <Col xs={2} md={1}>                                    
                                            <Form.Check.Input data-redirect={option.redirect} disabled={disabled} type={'radio'} required={!item.optional} defaultChecked={value === String(option.value)} name={item.name} value={option.value} onChange={onChange}/>
                                        </Col>
                                        <Col xs={10} md={11}>
                                            <Form.Check.Label>
                                                <div dangerouslySetInnerHTML={{__html: option.label}}></div>
                                            </Form.Check.Label>                                        
                                        </Col>
                                    </Form.Check>
                                ) : (
                                    <Form.Check type={'radio'} id={item.name+`-${indexOption}`}>
                                        <Form.Check.Input type={'radio'} data-redirect={option.redirect} disabled={disabled} required={!item.optional} defaultChecked={value == String(option)} name={item.name} value={option} onChange={onChange}/>
                                        <Form.Check.Label>
                                            <div dangerouslySetInnerHTML={{__html: option}}></div>  
                                        </Form.Check.Label>
                                    </Form.Check>
                                )
                            }
                        </>
                    ))
                }
            </div>
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}

export function Lecture({item}:Props){
    return (
        <Form.Group>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div></Form.Label>
            {
                item.complement && (
                    <div dangerouslySetInnerHTML={{__html: item.complement}}></div>                
                )
            }
        </Form.Group>
    )
}

export function Checkbox({item, value, onChange, disabled}:Props){
    return (
        <Form.Group controlId={item.name}>
            <Form.Label><div dangerouslySetInnerHTML={{__html: item.title}}></div> </Form.Label>
            <div>
                {
                    item.options.map((option:any,indexOption:number)=>(
                        <>
                            {
                                option.label ? (
                                    <Form.Check required={!item.optional} defaultChecked={value ? value.includes(option.value) : false }  data-checkbox="true" disabled={disabled} label={<div dangerouslySetInnerHTML={{__html: option.label}}></div>} name={item.name} type={"checkbox"} id={`inline-${item.name}-`+indexOption} value={option.value} onChange={onChange} />
                                ) : (
                                    <Form.Check required={!item.optional} defaultChecked={value ? value.includes(option) : false } data-checkbox="true"  disabled={disabled} label={option} name={item.name} type={"checkbox"} id={`inline-${item.name}-`+indexOption} value={option} onChange={onChange} />
                                )
                            }
                        </>     
                    ))
                }
            </div>
            <Form.Control.Feedback type="invalid">Es necesario llenar este campo</Form.Control.Feedback>
        </Form.Group>
    )
}